import {
  recursos1,
  recursos2,
  recursos3,
  recursos4,
  line,
} from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'recursos ',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 12,
        data: [
          {
            type: 'img',
            img: line,
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 12,
        className: 'mt-10',
      },
      {
        type: 'col',
        cols: 4,
        data: [
          {
            type: 'hero',
            cols: 10,
            sm: 10,
            md: 10,
            star: 'top',
            title: [
              {
                text: 'ASÍ ASIGNAMOS NUESTROS RECURSOS',
              },
            ],
          },

          {
            type: 'img',
            img: recursos1,
            md: 2,
            sm: 2,
            cols: 4,
          },
        ],
      },
      {
        type: 'col',
        cols: 8,
        md: 12,
        sm: 12,
        data: [
          {
            type: 'placeholder',
            cols: 1,
            md: 1,
            sm: 3,
            className: 'dn-sm',
          },
          {
            type: 'img',
            img: recursos4,
            className: 'animate__fast text-center max-width-img',
            side: 'animate__bounceIn',
            figcaption: 'Derechos Sexuales y Reproductivos',
            cols: 3,
            md: 3,
            sm: 12,
          },
          {
            type: 'placeholder',
            cols: 1,
            md: 1,
            sm: 3,
            className: 'dn-sm',
          },
          {
            type: 'img',
            img: recursos2,
            className: 'animate__fast text-center max-width-img',
            side: 'animate__bounceIn',
            figcaption: 'Comunicaciones',
            delay: 1,
            cols: 3,
            md: 3,
            sm: 12,
          },
          {
            type: 'placeholder',
            cols: 1,
            md: 1,
            sm: 12,
            className: 'dn-sm',
          },
          {
            type: 'img',
            img: recursos3,
            className: 'animate__fast text-center max-width-img',
            side: 'animate__bounceIn',
            delay: 2,
            figcaption: 'Violencia contra las mujeres y las niñas',
            cols: 3,
            md: 3,
            sm: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 12,
        data: [
          {
            type: 'img',
            className: 'animate__fast max-width-img',
            side: 'none',
            delay: 3,
            img: line,
            cols: 12,
          },
        ],
      },
    ],
  },
]
