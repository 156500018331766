import {
  trabajamos1,
  trabajamos2,
  trabajamos3,
} from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'trabajamos  ',
    data: [
      {
        type: 'col',
        cols: 8,
        md: 8,
        sm: 12,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '11',
              },
            ],
          },
          {
            type: 'hero',
            cols: 12,
            title: [
              {
                text: 'TRABAJAMOS PARA QUE OTRA REALIDAD SEA POSIBLE',
              },
            ],
          },

          {
            type: 'text',
            cols: 12,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text:
                  'Además de la impunidad generalizada, los feminicidios están rodeados de una falta de reconocimiento a la dignidad de las víctimas y sus familias, la cual se manifiesta a través de cuestionamientos, descalificaciones y juicios.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    className: 'trabajamos-text mt-5',
    data: [
      {
        type: 'col',
        cols: 8,
        data: [
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Por ello, en CDD y el OCNF buscamos cambiar este discurso, recuperando la memoria de las víctimas.',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'Así nació una serie de tres cortometrajes que muestra',
              },
              {
                type: 'mark',
                color: 'green',
                text:
                  ' la gravedad del feminicidio (Indignación), los espacios vacíos dejados por cada ausencia (Memoria) y el activismo de sus familias en la búsqueda de la justicia (Esperanza).',
              },
              {
                type: 'p',
                text:
                  ' Si bien denunciamos la violencia feminicida y recordamos a sus víctimas, también trabajamos para que otra realidad sea posible. En este camino, las mamás nos enseñan que juntas somos más fuertes.',
              },
            ],
          },
          {
            type: 'img',
            img: trabajamos3,
            delay: 1,
            hover: true,
            side: 'animate__fadeInLeft ',
            cols: 12,
          },
        ],
      },
      {
        type: 'col',
        cols: 4,
        md: 10,
        sm: 10,
        data: [
          {
            type: 'img',
            img: trabajamos2,
            hover: true,
            delay: 1,
            cols: 12,
            className: 'max-width-img',
          },
          {
            type: 'placeholder',
            cols: 4,
          },
          {
            type: 'button',
            className: 'button-center ',
            text: 'Ver Videos',
            color: 'black',
            outline: true,
            cols: 8,
            size: 'md',
            link: 'https://www.youtube.com/watch?v=fkpyeDtN1Q0',
          },
        ],
      },
    ],
  },
]
