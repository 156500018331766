export const DATA = [
  {
    type: 'row',
    className: 'hero ',
    data: [
      {
        type: 'col',
        cols: 3,
      },
      {
        type: 'col',
        cols: 6,
        data: [
          {
            type: 'text',
            className: 'text-center font-EB ',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'El 2019 fue un año de',
              },
              {
                type: 'p',
                color: 'green',
                text: ' movilización, celebración y cambio ',
              },
              {
                type: 'p',
                text:
                  'para Católicas por el Derecho a Decidir (CDD) y para el movimiento feminista.',
              },
            ],
          },
        ],
      },

      {
        type: 'col',
        cols: 3,
      },
    ],
  },
  {
    type: 'row',
    className: 'intro-info',
    data: [
      {
        type: 'col',
        cols: 3,
      },
      {
        type: 'col',
        cols: 6,
        className: 'text-center',
        data: [
          {
            type: 'button',
            cols: 6,
            text: 'Descargar informe completo',
            color: 'black',
            outline: true,
            size: 'md',
            link:
              'https://drive.google.com/file/d/1fhsBtcBcRdIuEOI1Ltt08IzI-d7gGy_e/view?usp=sharing',
          },
          {
            type: 'button',
            cols: 6,
            text: 'Download full report',
            color: 'black',
            outline: true,
            size: 'md',
            link:
              'https://drive.google.com/file/d/1Eq4cWQyHGeyy5YZ9ZXrGvu_pImWNdey9/view?usp=sharing',
          },
        ],
      },
      {
        type: 'col',
        cols: 3,
      },
    ],
  },
]
