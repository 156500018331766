import { america1 } from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'raices',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 5,
        data: [
          {
            type: 'hero',
            cols: 11,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '7',
              },
            ],
          },
          {
            type: 'hero',
            cols: 11,
            title: [
              {
                text: '¡AMÉRICA LATINA SERÁ TODA FEMINISTA!',
              },
            ],
          },

          {
            type: 'text',
            cols: 11,
            className: 'font-EB  font-size-md',
            text: [
              {
                type: 'p',
                text:
                  'Desde Argentina -en donde nuestras compañeras de CDD han jugado un papel fundamental para la despenalización social del aborto-, ',
              },
              {
                type: 'p',
                color: 'green',
                text:
                  'la marea verde inundó América Latina y llegó para quedarse.',
              },
            ],
          },

          {
            type: 'text',
            cols: 11,
            text: [
              {
                type: 'p',
                text:
                  'Aprovechando este ímpetu, en CDD México continuamos apoyando a nuestras organizaciones hermanas en Argentina, Bolivia, Brasil, Colombia y Perú, facilitando procesos de fortalecimiento institucional e incidencia política para promover el acceso al aborto legal y seguro. En medio de contextos adversos, ',
              },
              {
                type: 'mark',
                color: 'green',
                text:
                  'las CDD lograron colocar sus argumentos en procesos judiciales y garantizar la oferta de servicios de aborto',
              },
              {
                type: 'p',
                text:
                  ' en instituciones sanitarias locales, incluyendo la disponibilidad de profesionales no objetores de conciencia. ',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 7,
        className: 'mt-15',
        data: [
          {
            type: 'img',
            hover: true,
            img: america1,
            cols: 12,
          },
        ],
      },
    ],
  },
]
