import { echando1 } from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'echando',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10 sm-mt-8',
      },
      {
        type: 'col',
        cols: 5,
        data: [
          {
            type: 'hero',
            className: 'star-bottom',
            star: 'bottom',
            cols: 12,
            title: [
              {
                text: '8',
              },
            ],
          },
          {
            type: 'hero',
            cols: 12,
            title: [
              {
                text: 'ECHANDO RAÍCES',
              },
            ],
          },

          {
            type: 'text',
            cols: 12,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text: 'CDD continuó apoyando el trabajo de',
              },
              {
                type: 'p',
                color: 'yellow',
                text: ' EDD ',
              },
              {
                type: 'p',
                text: 'y la ',
              },
              {
                type: 'p',
                color: 'yellow',
                text: ' ACRD ',
              },
              {
                type: 'p',
                text:
                  'en torno a la legalización de la anticoncepción de emergencia y la despenalización del aborto en tres causales.',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Una evaluación externa realizada en 2019 corroboró que ambas organizaciones se distinguen, haciendo un aporte muy significativo, en razón del elemento de fe e interreligioso, que resulta novedoso para tomadores/as de decisión, organizaciones de sociedad civil y la población en general; además de que contribuyen en la lucha contra los fundamentalismos religiosos.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        cols: 6,
        className: 'mt-10',
        data: [
          {
            type: 'img',
            hover: true,
            img: echando1,
            cols: 12,
          },
        ],
      },
    ],
  },
]
