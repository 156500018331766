import {
  cuerpo1,
  cuerpo2,
  cuerpo3,
  cuerpo4,
  cuerpo5,
} from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'raices',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10',
      },
      {
        type: 'col',
        cols: 5,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '9',
              },
            ],
          },
          {
            type: 'hero',
            cols: 12,
            title: [
              {
                text: 'NUESTRO CUERPO, NUESTRO TERRITORIO',
              },
            ],
          },

          {
            type: 'text',
            cols: 12,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text:
                  'Más de la mitad del país tiene una alerta de violencia de género (AVG) debido a la violencia feminicida. Varias organizaciones hemos trabajado ',
              },
              {
                type: 'p',
                color: 'pink',
                text:
                  'no sólo para que las alertas se decreten, sino para que las medidas que emanan de ellas se implementen.',
              },
            ],
          },
          {
            type: 'text',
            className: 'font-raleway font-size-md-1',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'PLANES ESTRATÉGICOS',
              },
            ],
          },
          {
            type: 'text',
            className: 'font-italic',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'con indicadores para la implementación AVG',
              },
            ],
          },
          {
            type: 'img',
            img: cuerpo1,

            className: 'text-center',
            figcaptionTop: 'Chiapas',
            cols: 3,
            md: 3,
            sm: 6,
          },
          {
            type: 'img',
            img: cuerpo2,
            className: 'text-center',
            figcaptionTop: 'CDMX',

            cols: 3,
            md: 3,
            sm: 6,
          },
          {
            type: 'img',
            img: cuerpo3,
            className: 'text-center',
            figcaptionTop: 'Jalisco',

            cols: 3,
            md: 3,
            sm: 6,
          },
          {
            type: 'img',
            img: cuerpo4,
            className: 'text-center',
            figcaptionTop: 'Nuevo León',

            cols: 3,
            md: 3,
            sm: 6,
          },
        ],
      },
      {
        type: 'col',
        cols: 1,
      },
      {
        type: 'col',
        cols: 6,
        data: [
          {
            type: 'text',
            className: 'h2 mt-10',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'En 2019, desde CDD y el OCNF trabajamos con los gobiernos estatales de la Ciudad de México, Chiapas, Nuevo León y Jalisco para desarrollar planes estratégicos con indicadores para medir la implementación de las AVG. En el Estado de México, Colima y Puebla, estamos trabajando en medidas de procuración de justicia y prevención. Particularmente en Colima, coadyuvamos a hacer un diagnóstico sobre la investigación penal del feminicidio. ',
              },
            ],
          },
          {
            type: 'img',
            img: cuerpo5,
            cols: 12,
          },
        ],
      },
    ],
  },
]
