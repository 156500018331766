import {
  sorjuana1,
  sorjuana2,
  sorjuana3,
} from '../../../../Components/ImagesBySection'

export const DATA = [
  {
    type: 'row',
    className: 'sorjuana ',
    data: [
      {
        type: 'col',
        cols: 12,
        className: 'mt-10  sm-mt-8',
      },
      {
        type: 'col',
        cols: 5,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'star-bottom',
            star: 'bottom',
            title: [
              {
                text: '13',
              },
            ],
          },
          {
            type: 'hero',
            cols: 12,
            title: [
              {
                text: '¡SOR JUANAAA...!',
              },
            ],
          },

          {
            type: 'text',
            cols: 12,
            className: 'font-EB font-size-md',
            text: [
              {
                type: 'p',
                text:
                  '¡Sí! Sor Juana y el padre Beto regresaron en una décima temporada de Catolicadas.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 7,
        className: 'text-center',
        data: [
          {
            type: 'img',
            img: sorjuana1,
            md: 4,
            sm: 12,
            className: 'max-width-img mt-5',
            hover: true,
            cols: 5,
          },
          {
            type: 'text',
            cols: 5,
            md: 8,
            sm: 8,
            className: 'text-center mt-5',
            star: 'top center',
            text: [
              {
                type: 'strong',
                className: 'font-size-lg font-raleway',
                number: 14221,
                start: 10021,
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'font-EB',
                text: 'Reacciones',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'font-EB',
                text: 'positivas',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    className: 'sorjuana-text mt-5',
    data: [
      {
        type: 'col',
        cols: 4,
        data: [
          {
            type: 'text',
            cols: 12,
            star: 'top',
            text: [
              {
                type: 'p',
                text:
                  'En la encuesta anual que lanzamos sobre la serie, seguimos encontrando que Catolicadas contribuye a brindar información, cambiar actitudes e influenciar sus acciones.',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 4,
        data: [
          {
            type: 'hero',
            className: 'text-center',
            cols: 12,
            title: [
              {
                color: 'yellow',
                text: '57%',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            className: 'text-center',
            text: [
              {
                type: 'p',
                text:
                  'Dejó de participar en actividades organizadas por la Iglesia en contra',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'font-italic',
                text:
                  'del aborto o los matrimonios entre personas del mismo sexo',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 4,
        className: 'text-center',
        data: [
          {
            type: 'hero',
            className: 'text-center',
            cols: 12,
            title: [
              {
                color: 'yellow',
                text: '78%',
              },
            ],
          },
          {
            type: 'text',
            className: 'text-center',
            cols: 12,
            text: [
              {
                type: 'p',
                text:
                  'Reconoce que Dios nos ama por igual sin importar la orientación sexual',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'col',
    cols: 12,
    className: 'mt-5',
  },
  {
    type: 'row',
    className: 'sorjuana-text mt-5',
    data: [
      {
        type: 'col',
        cols: 4,

        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'text-center',
            title: [
              {
                color: 'yellow',
                text: '70%',
              },
            ],
          },
          {
            type: 'text',
            className: 'text-center',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'Ha defendido a una persona de la Comunidad LGBTQ+',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'font-italic',
                text: 'al ser discriminada',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 4,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'text-center',

            title: [
              {
                color: 'yellow',
                text: '57%',
              },
            ],
          },
          {
            type: 'text',
            className: 'text-center',
            cols: 12,
            text: [
              {
                type: 'p',
                text: 'Ha decidido usar métodos anticonceptivos',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'font-italic',
                text: 'para tener relaciones ',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'font-italic',
                text: 'sexuales seguras',
              },
            ],
          },
        ],
      },
      {
        type: 'col',
        cols: 4,
        data: [
          {
            type: 'hero',
            cols: 12,
            className: 'text-center',
            title: [
              {
                color: 'yellow',
                text: '53%',
              },
            ],
          },
          {
            type: 'text',
            cols: 12,
            className: 'text-center',
            text: [
              {
                type: 'p',
                text: 'Reconoce que la Iglesia no puede discriminar',
              },
              {
                type: 'br',
              },
              {
                type: 'br',
              },
              {
                type: 'p',
                className: 'font-italic',
                text: 'a ninguna persona por lo que piense, exprese y decida.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'row',
    className: 'sorjuana-bloques mt-10',
    data: [
      {
        type: 'col',
        cols: 12,
        data: [
          {
            type: 'img',
            className: 'sorjuana-bloques-img-1',
            img: sorjuana2,
            delay: 1,
            hover: true,
            side: 'animate__bounceIn ',
            cols: 4,
            md: 12,
          },
          {
            type: 'text',
            cols: 4,
            md: 12,
            sm: 12,
            className: 'sorjuana-bloques-box',
            text: [
              {
                type: 'p',
                text:
                  '“Me ayudó a darme cuenta que la diversidad es también parte del plan de Dios.”',
              },
            ],
          },
          {
            type: 'text',
            cols: 4,
            md: 12,
            sm: 12,
            className: 'sorjuana-bloques-box',
            text: [
              {
                type: 'p',
                text:
                  '“Me hizo tomar valor para contarle a mi familia que fuí víctima de abuso sexual.” ',
              },
            ],
          },
          {
            type: 'text',
            cols: 4,
            md: 12,
            sm: 12,
            className: 'sorjuana-bloques-box',
            text: [
              {
                type: 'p',
                text:
                  '“Me he vuelto más crítico respecto a mi fe..., Me hicieron entender más cosas de la iglesia católica que me hicieron repensar mi religión y no alejarme de ella.” ',
              },
            ],
          },
          {
            type: 'text',
            cols: 4,
            md: 12,
            sm: 12,
            className: 'sorjuana-bloques-box',
            text: [
              {
                type: 'p',
                text:
                  '“Ahora estoy por formar un grupo juvenil en la parroquia de mi casa con ideas que me dio Catolicadas.” ',
              },
            ],
          },
          {
            type: 'text',
            cols: 4,
            md: 12,
            sm: 12,
            className: 'sorjuana-bloques-box',
            text: [
              {
                type: 'p',
                text:
                  '“Me ayudó a ser yo misma. Aún siendo divorciada, Dios no me ha abandonado. No es pecado ser así.”',
              },
            ],
          },
          {
            type: 'text',
            cols: 4,
            md: 12,
            sm: 12,
            className: 'sorjuana-bloques-box',
            text: [
              {
                type: 'p',
                text:
                  '“Me ha hecho hablar de temas de sexualidad con mis hijos.”',
              },
            ],
          },
          {
            type: 'text',
            cols: 4,
            md: 12,
            sm: 12,
            className: 'sorjuana-bloques-box',
            text: [
              {
                type: 'p',
                text:
                  '“Me han hecho pensar en que si aborto o no, es mi decisión y Dios no me va a querer menos por ello.” ',
              },
            ],
          },
          {
            type: 'img',
            img: sorjuana3,
            hover: true,
            delay: 2,
            side: 'animate__bounceIn ',
            cols: 4,
            md: 12,
            sm: 12,
          },
        ],
      },
    ],
  },
]
